.home .jumbotron {
    background: none;
}

.home .jumbotron > .container {
    margin-top: 55px;
}

.home .collect-options {
    margin-top: 12px;
}

.home .collect-options button {
    background: none;
    border: none;
}

.home .collect-options button img {
    display: inline-block;
    max-width: 180px;
    box-shadow: 1px 1px 3px grey;
}

.home .collect-options button:hover img,
.home .collect-options button:focus img {
    box-shadow: 1px 1px 7px grey;
}

.home .collect-options button:focus {
    outline: 0;
}

.home .collect-options button.clicked img {
    box-shadow: 0px 0px 7px #b48300;
}

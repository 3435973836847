/*
.menu-link{
  color: "grey" !important;
} */

/* .container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
} */

html,
body {
  overflow-x: hidden;
  width: 100%;
}

button {
  border: none;
}
button:focus {
  outline: none !important;
}

#menu-link:hover {
  color: #b48300 !important;
  cursor: pointer;
}

.center {
  margin: auto;
  width: 50%;
  padding: 10px;
}

.map-container {
  top: 56px;
  width: 50%;
  height: calc(100% - 58px);
  position: fixed;
  right: 0;
}

.page-container {
  top: 56px;
  width: 100%;
  height: calc(100% - 58px);
  position: fixed;
}

.search-container {
  top: 56px;
  width: 50%;
  height: calc(100% - 58px);
  position: fixed;
  right: 50%;
}

.info {
  border: 1px solid grey;
  width: 100px;
  font-size: large;
}

.item {
  height: 300px;
  display: flex;
  flex-direction: row;
  width: 45%;
  border-bottom: 1px solid #eeeeee;
  padding-top: 10px;
  margin: 0 2% 0 2%;
  text-decoration: none;
}

.card-img-top {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.card-img-left {
  width: 100% !important;
  object-fit: cover;
  height: 180px !important;
}

.fill {
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  overflow: hidden;
  object-fit: cover !important;
}

.fill img{
  position: absolute;
}

.card-title {
  line-height: 1em;
  height: 38px;
  overflow: hidden;
  /* height: 34px; */
  font-size: large;
  font-weight: bold;
  word-break: break-word !important;
}

.card-subtitle {
  line-height: 1em;
  /* height: 26px;  */
  overflow: hidden;
  word-break: break-word !important;
}

.card-page-title {
  line-height: 1.1em;
  /* height: 55px; */
  overflow: hidden;
  /* height: 34px; */
  font-size: large;
  font-weight: bold;
  word-break: break-word !important;
}

.card-page-subtitle {
  line-height: 1.1em;
  /* height: 26px; */
  overflow: hidden;
  word-break: break-word !important;
}

.item-title {
  line-height: 1em;
  /* height: 10em;  */
  overflow: hidden;
  word-break: break-word !important;
}

.card-text {
  word-break: break-word !important;
}

.home-option {
  padding: 5px;
  width: 150px;
  filter: drop-shadow(1px 1px 3px grey);
}

.home-option:hover {
  filter: drop-shadow(1px 1px 7px grey);
  cursor: pointer;
}

.home-option-clicked {
  width: 150px;
  filter: drop-shadow(0px 0px 7px #b48300);
}

.main-caption {
  color: #b48300;
}

@media only screen and (max-width: 10000px) {
  .banner-img-top {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  .home-jumbo {
    height: 800px;
  }
  .home-banner {
    width: 110%;
  }
  .home-iwant {
    width: 20%;
  }
  .nearby-card {
    padding: 6px;
    width: 220px;
  }
  .item-card {
    width: 180px !important;
    height: 250px;
  }
  .page-card {
    width: 30% !important;
    margin: 10px 20px;
  }
  .right-items {
    padding-top: 5%;
  }
  .hashtag {
    width: 60%;
  }
  .image-gallery-content.fullscreen img {
    width: 30% !important;
    height: 100% !important;
    z-index: 99999 !important;
  }
  .image-gallery.fullscreen-modal img {
    width: 100% !important;
    height: 100% !important;
  }
  .image-gallery.fullscreen-modal {
    z-index: 9999 !important;
  }
}

@media only screen and (max-width: 2000px) {
  .banner-img-top {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  .home-jumbo {
    height: 800px;
  }
  .home-banner {
    width: 110%;
  }
  .home-iwant {
    width: 20%;
  }
  .nearby-card {
    padding: 6px;
    width: 220px;
  }
  .item-card {
    width: 180px !important;
    height: 250px;
  }
  .page-card {
    width: 30% !important;
    margin: 10px 20px;
  }
  .right-items {
    padding-top: 5%;
  }
  .hashtag {
    width: 60%;
  }
  .image-gallery-content.fullscreen img {
    width: 30% !important;
    height: 100% !important;
    z-index: 99999 !important;
  }
  .image-gallery.fullscreen-modal img {
    width: 100% !important;
    height: 100% !important;
  }
  .image-gallery.fullscreen-modal {
    z-index: 9999 !important;
  }
}

@media only screen and (max-width: 1200px) {
  .banner-img-top {
    width: 100%;
    height: 230px;
    object-fit: cover;
  }
  .home-jumbo {
    height: 750px;
  }
  .home-banner {
    width: 110%;
  }
  .home-iwant {
    width: 20%;
  }
  .nearby-card {
    padding: 6px;
    width: 220px;
  }
  .item-card {
    width: 180px !important;
    height: 250px;
  }
  .page-card {
    width: 70% !important;
    margin: 10px 20px;
  }
  .hashtag {
    width: 60%;
  }
  .image-gallery-content.fullscreen img {
    width: 40% !important;
    height: 100% !important;
    z-index: 99999 !important;
  }
  .image-gallery.fullscreen-modal img {
    width: 100% !important;
    height: 100% !important;
  }
  .image-gallery.fullscreen-modal {
    z-index: 9999 !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  .banner-img-top {
    width: 100%;
    height: 230px;
    object-fit: cover;
  }
  .home-jumbo {
    height: 200px;
  }
  .preview-small {
    display: none;
  }
  .home-banner {
    width: 110%;
  }
  .home-iwant {
    width: 20%;
  }
  .nearby-card {
    padding: 6px;
    width: 220px;
  }
  .item-card {
    width: 260px !important;
    height: 250px;
  }
  .page-card {
    width: 45% !important;
    margin: 10px 20px;
  }
  .right-items {
    padding-top: 5%;
  }
  .hashtag {
    width: 50%;
  }
  .image-gallery-content.fullscreen img {
    width: 100% !important;
    height: 100% !important;
    z-index: 99999 !important;
  }
  .image-gallery.fullscreen-modal img {
    width: 100% !important;
    height: 100% !important;
  }
  .image-gallery.fullscreen-modal {
    z-index: 9999 !important;
  }
}

@media only screen and (min-width: 480px) and (max-width: 1200px) and (-webkit-min-device-pixel-ratio: 1) {
  .banner-img-top {
    width: 100%;
    height: 230px;
    object-fit: cover;
  }
  .home-jumbo {
    height: 200px;
  }
  .home-banner {
    width: 120%;
  }
  .home-iwant {
    width: 20%;
  }
  .nearby-card {
    padding: 6px;
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }
  .item-card {
    width: 150px !important;
    height: 250px;
  }
  .page-card {
    width: 100% !important;
    margin: 10px 20px;
  }
  .right-items {
    padding-top: 0px;
  }
  .hashtag {
    width: 60%;
  }
  .image-gallery-content.fullscreen img {
    width: 100% !important;
    height: 100% !important;
    z-index: 99999 !important;
  }
  .image-gallery.fullscreen-modal img {
    width: 100% !important;
    height: 100% !important;
  }
  .image-gallery.fullscreen-modal {
    z-index: 9999 !important;
  }
  .modal-dialog {
    max-width: unset !important;
    right: calc(100% - 105%) !important;
  }
}

@media only screen and (min-width: 0px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .banner-img-top {
    width: 100%;
    height: 230px;
    object-fit: cover;
  }
  .home-jumbo {
    height: 670px;
  }
  .home-banner {
    width: 80%;
  }
  .home-iwant {
    width: 20%;
  }
  .nearby-card {
    padding: 6px;
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }
  .item-card {
    width: 150px !important;
    height: 250px;
  }
  .page-card {
    width: 100% !important;
    margin: 5px 20px;
  }
  /* .fill {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    max-height: 100px;
    max-width: 100px;
  }   */
  .right-items {
    padding-top: 0%;
  }
  .hashtag {
    width: 100%;
  }
  .image-gallery-content.fullscreen img {
    width: 100% !important;
    height: 100% !important;
    z-index: 99999 !important;
  }
  .image-gallery.fullscreen-modal img {
    width: 100% !important;
    height: 100% !important;
  }
  .image-gallery.fullscreen-modal {
    z-index: 9999 !important;
  }
  .modal-dialog {
    max-width: unset !important;
    right: calc(100% - 105%) !important;
  }
}

@media only screen and(device-height: 568px) and (device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
  .banner-img-top {
    width: 100%;
    height: 230px;
    object-fit: cover;
  }
  .home-jumbo {
    height: 670px;
  }
  .home-banner {
    width: 90%;
  }
  .home-iwant {
    width: 20%;
  }
  .nearby-card {
    padding: 6px;
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }
  .item-card {
    width: 260px !important;
    height: 250px;
  }
  .page-card {
    width: 100% !important;
    margin: 10px 20px;
  }
  .right-items {
    padding-top: 5%;
  }
  .hashtag {
    width: 100%;
  }
  .image-gallery-content.fullscreen img {
    width: 100% !important;
    height: 100% !important;
    z-index: 99999 !important;
  }
  .image-gallery.fullscreen-modal img {
    width: 100% !important;
    height: 100% !important;
  }
  .image-gallery.fullscreen-modal {
    z-index: 9999 !important;
  }
  .modal-dialog {
    max-width: unset !important;
    right: calc(100% - 105%) !important;
  }
}

.pinDefault {
  position: relative;
  border-radius: 50% 50% 50% 0;
  border: 10px solid blue;
  width: 20px;
  height: 20px;
  transform: rotate(-45deg);
  right: 10px;
  bottom: 20px;
}

.pinMouseOver {
  position: relative;
  border-radius: 50% 50% 50% 0;
  border: 10px solid grey;
  width: 20px;
  height: 20px;
  transform: rotate(-45deg);
  right: 10px;
  bottom: 20px;
}

.App {
  font-family: "Open Sans", "Roboto", "Arial", sans-serif;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* .image-gallery-slide img{
  width: 100% !important;
  height: 260px !important;
  object-fit: cover;
} */

/* .image-gallery-content{
  height: 100% !important;
  width: 100% !important;
} */

/* .image-gallery-content.fullscreen img{
  height: 100% !important;
  width: 100% !important;
} */

.image-gallery-content {
  max-width: 100% !important;
  height: auto !important;
}

.image-gallery-slide {
  width: 100% !important;
  height: 100% !important;
  object-fit: overflow !important;
}

.image-gallery-content img {
  width: 100% !important;
  height: 250px !important;
  object-fit: overflow !important;
}

.image-gallery-thumbnails img {
  width: 100%;
  height: 50px !important;
  object-fit: cover;
}

.image-gallery-slides {
  width: 100% !important;
  height: 100% !important;
  object-fit: overflow;
}

.basic-multi-select {
  z-index: 999;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.create-title {
  text-align: left;
}

figure.effect-bubba {
  background: white;
  cursor: pointer;
}

figure.effect-bubba img {
  opacity: 1;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
  z-index: -5;
}

figure.effect-bubba:hover img {
  opacity: 0.7;
}

figure.effect-bubba figcaption::before,
figure.effect-bubba figcaption::after {
  position: absolute;
  top: 10px;
  right: 10px;
  bottom: 10px;
  left: 10px;
  content: "";
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
}

figure.effect-bubba figcaption::before {
  border-top: 1px solid #b48300;
  border-bottom: 1px solid #b48300;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
}

figure.effect-bubba figcaption::after {
  border-right: 1px solid #b48300;
  border-left: 1px solid #b48300;
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
}

figure.effect-bubba h6 {
  padding-top: 10%;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0, -20px, 0);
  transform: translate3d(0, -20px, 0);
}

figure.effect-bubba p {
  /* padding: 5px 2.5em; */
  /* opacity: 100; */
  /* -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0, 20px, 0);
  transform: translate3d(0, 20px, 0); */
}

figure.effect-bubba:hover figcaption::before,
figure.effect-bubba:hover figcaption::after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

figure.effect-bubba:hover h6 {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

figure.effect-bubba:hover p {
  opacity: 0;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

figcaption .row {
  margin: 0;
}

figcaption .progress {
  padding: 15px 0;
  height: 50px;
  background-color: inherit;
}

figcaption .sub {
  width: 20%;
}

figcaption .sub-price {
  width: 80%;
  text-align: right;
}

.modal-dialog {
  width: 80%;
  max-width: 90% !important;
}

@import url("https://fonts.googleapis.com/css?family=Montserrat:400,400i,700");
#clap {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  /* min-height: 100vh; */
  font-family: Montserrat;
}

.clap {
  position: relative;
  outline: 1px solid transparent;
  /* left: 80px; */

  /* border-radius: 50%; */
  /* border: 1px solid rgba(189, 195, 199, 1); */
  width: 80px;
  height: 80px;
  /* background: none; */
}
.clap:hover {
  cursor: pointer;
  /* border: 1px solid rgba(39, 174, 96, 1); */
  transition: border-color 0.3s ease-in;
  cursor: pointer;
}
.clap:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  border-radius: 50%;
  width: 79px;
  height: 79px;
}
.clap:hover:after {
  animation: shockwave 1s ease-in infinite;
}
.clap svg {
  width: 40px;
  fill: none;
  stroke: rgba(39, 174, 96, 1);
  stroke-width: 2px;
}
.clap svg.checked {
  fill: rgba(39, 174, 96, 1);
  stroke: #fff;
  stroke-width: 1px;
}
.clap .clap--count {
  position: absolute;
  top: -50px;
  left: 20px;
  font-size: 0.8rem;
  color: white;
  background: rgba(39, 174, 96, 1);
  border-radius: 50%;
  height: 40px;
  width: 40px;
  line-height: 40px;
}
.clap .clap--count-total {
  position: absolute;
  font-size: 1 em;
  width: 80px;
  text-align: center;
  /* right:20px; */
  top: 0px;
  color: rgba(189, 195, 199, 1);
}

.badge {
  margin: 0 0.1rem;
}

/*==================== Message ======================*/
#message {
  position: absolute;
  bottom: 20px;
  color: rgba(39, 174, 96, 1);
  line-height: 1.52rem;
  padding: 1rem;
  font-size: 0.9rem;
}
#message a {
  color: rgba(189, 195, 199, 1);
}
@keyframes shockwave {
  0% {
    transform: scale(1);
    box-shadow: 0 0 2px rgba(39, 174, 96, 1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
    box-shadow: 0 0 50px rgba(20, 91, 50, 1),
      inset 0 0 10px rgba(39, 174, 96, 1);
  }
}
